<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        v-show="!this.$route.params.document"
      >
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :qDelete="Qdelete"
          :qTotal="Totals"
          :PageResponse="'paiements'"
          :AllResponse="'allpaye'"
          :TotalResponse="'totals_paye'"
          :title="title"
          :headers="headers"
          :field_list="['PAYE_DATE', 'NOPAYE']"
          :filename="'paiements'"
          :sheetname="'paiements'"
          @hrf="OpenPayeForm"
          @open="OpenPayeForm"
          @rowselect="PayeChange"
          @delete="PayeDelete"
          :del_disable="paiement.statut_id == '17' || paiement.nbdocs > 0"
          :showedit="false"
          :key="klist"
          :TypeScope="paye_type"
          :VarScope="
            mybl == 'n' &&
            ($store.state.auth.includes('05021') || $store.state.isadmin)
              ? 2
              : 1
          "
          :Add="editer"
          :del="editer"
          :search_elm="filtre"
          :exp2_label="'Initial'"
          @col_btn3_click="show_attached"
        >
        </listitemspage>
      </v-col>
      <v-navigation-drawer
        v-model="showDetail"
        absolute
        temporary
        right
        width="700"
      >
        <v-card flat>
          <v-card-title>
            <v-toolbar dense>
              <v-app-bar-nav-icon></v-app-bar-nav-icon>
              <v-toolbar-title> Lettrages </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <listitems
              :list="detail_list"
              :headers="headers_list"
              :qDelete="Qdetaildelete"
              :master="false"
              :key="aff"
              :add="
                (paye_type == 3 || paiement.facture == 0) &&
                editer &&
                paiement.taux_avance == 0 &&
                paiement.statut_id == '17' &&
                paiement.reste > 0
              "
              :del="
                (paye_type == 3 || paiement.facture == 0) &&
                editer &&
                paiement.taux_avance == 0
              "
              :showedit="false"
              @open="OpenDetailForm"
              @delete="refresh"
            >
            </listitems>
          </v-card-text>
        </v-card>
      </v-navigation-drawer>
    </v-row>

    <payeform
      :item="paiement"
      :key="kf"
      :paye_type="paye_type"
      :showForm="!isPayeClosed"
      :det="detail_list.length > 0"
      @close="closePayeForm"
      :banks="banks"
      :agences="agences"
      :tiers_agences="tiers_agences"
      :facts_list="facts_list"
      :editer="editer"
      :valider="valider"
      :modes="modes"
      :comptes="comptes"
      :document="document"
    >
    </payeform>
    <payedetailform
      :item="payedetail"
      :items="detail_list"
      :paiement="paiement"
      :paye_type="paye_type"
      :key="dkey"
      :showForm="!isDetailClosed"
      @close="closeDetailForm"
      @change="refresh"
      :facts_list="facts_list"
      :mvms_list="mvms_list"
    >
    </payedetailform>
    <filelist
      :item="paiement"
      :showform="!isDocListClose"
      :key="fdocl"
      @close="isDocListClose = true"
      :editer="editer"
      :doc_type="5"
      :auth="auth"
    >
    </filelist>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import PAYE from "../graphql/Paiement/PAIEMENT.gql";
import ALLPAYE from "../graphql/Paiement/ALLPAYE.gql";
import AGENCES from "../graphql/Paiement/AGENCES.gql";
import ACTUAL_DPS from "../graphql/Paiement/ACTUAL_DPS.gql";
import DELETE_PAYE from "../graphql/Paiement/DELETE_PAYE.gql";
import MODES from "../graphql/Paiement/MODES.gql";
import TOTALS from "../graphql/Paiement/TOTALS.gql";
import DELETE_PAYE_DETAIL from "../graphql/Paiement/DELETE_PAYE_DETAIL.gql";
import { myBL } from "print/data.js";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    listitems: () => import("../components/ListItems.vue"),
    payeform: () => import("../components/PayeForm.vue"),
    filelist: () => import("../components/FileList.vue"),
    payedetailform: () => import("../components/PayeDetailForm.vue"),
  },
  props: {
    paye_type: Number,
  },
  data: () => ({
    filtre: null,
    klist: 0,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    loading: false,
    editer: false,
    valider: false,
    isDetailClosed: true,
    selitem: {},
    aff: 0,
    kf: 100,
    dkey: 200,
    fdocl: 1000,
    showDetail: false,
    headers: [
      {
        text: "Code",
        slot: "href",
        value: "nopaye",
        enum: "NOPAYE",
        selected: true,
        totlib: true,
        width: "120",
      },
      {
        text: "Site",
        value: "label",
        hiden: true,
        selected: true,
      },
      {
        text: "Date Operation",
        value: "paye_date",
        enum: "PAYE_DATE",
        slot: "date",
        selected: true,
        width: "110",
      },
      {
        text: "Code Tier",
        align: "text-left",
        value: "tier_code",
        enum: "TIER_CODE",
        selected: false,
      },
      {
        text: "Tier",
        align: "text-left",
        value: "tier_name",
        enum: "TIERS_NAME",
        selected: true,
      },
      {
        text: "Auxiliaire",
        align: "text-left",
        value: "auxiliaire",
        enum: "AUXILIAIRE",
        selected: false,
      },
      {
        text: "Mode de Paiement",
        value: "type_paye",
        enum: "TYPE_PAYE",
        list: [],
        selected: true,
      },
      {
        text: "Banque",
        value: "bank",
        enum: "BANK",
        list: [],
        selected: true,
      },
      {
        text: "Ref.",
        value: "nocheque",
        enum: "NOCHEQUE",
        selected: true,
      },
      {
        text: "Date Cheque/Virement",
        value: "date_cheque",
        enum: "DATE_CHEQUE",
        slot: "date",
        selected: true,

        width: "110",
      },

      {
        text: "Montant ",
        value: "montant",
        enum: "MONTANT",
        slot: "cur",
        selected: true,
        align: "end",
        result: "tot_paiements",
        column: "reste",
        valeur: "0",
        width: "150",
      },
      {
        text: "Lettré ",
        value: "ttc_det",
        enum: "TTC_DET",
        slot: "href4",
        result: "tot_ttc_det",
        selected: true,
        hiden: true,
        column: "statut_id",
        valeur: 17,
        align: "end",
        width: "150",
      },
      {
        text: "Reste ",
        value: "reste",
        enum: "RESTE",
        slot: "cur",
        result: "tot_reste",
        selected: true,
        hiden: true,
        align: "end",
        width: "150",
      },
      {
        text: "% Lettre ",
        value: "prc_lettre",
        enum: "PRC_LETTRE",
        slot: "progress",
        selected: true,
        hiden: true,
        align: "end",
        width: "100",
      },
      {
        text: "Agence de remise",
        value: "agence",
        enum: "AGENCE",
        list: [],
        hiden: true,
        selected: false,
      },

      {
        text: "Date Remise",
        value: "date_remise",
        enum: "DATE_REMISE",
        slot: "date",
        selected: false,
        hiden: true,
        width: "110",
      },
      {
        text: "Statut",
        value: "status_name",
        enum: "STATUS_NAME",
        align: "text-center",
        list: ["Brouillon", "Att. Lettrage", "Lettré", "Annulé"],
        selected: true,
      },
      {
        text: "Rapprochement",
        value: "status_bank",
        enum: "STATUS_BANK",
        list: [null, "Att. Rapprochement", "Accepté", "Rejeté"],
        align: "text-center",
        selected: true,
        width: "110",
      },
      {
        text: "Date Rappr.",
        value: "date_effet",
        enum: "DATE_EFFET",
        slot: "date",
        selected: false,
        width: "110",
      },
      {
        text: "Attachement",
        value: "nbdocs",
        enum: "NBDOCS",
        slot: "col_btn3",
        selected: true,
        icon: "mdi-attachment",
        tooltip: "Documents attachés",
        sortable: false,
        width: "80",
      },
    ],
    factheaders: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "Réference",
        value: "nofact",
        selected: true,
      },
      {
        text: "Date ",
        value: "fact_date",
        slot: "date",
        selected: true,
      },
      {
        text: "Montant",
        value: "ttc_fact",
        slot: "cur",
        align: "end",
        selected: true,
      },
      {
        text: "Montant Reglé",
        value: "montant",
        slot: "cur",
        align: "end",
        selected: true,
      },
    ],
    dpheaders: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "DP No",
        value: "nodp",
        selected: true,
      },
      {
        text: "Date DP",
        value: "dp_date",
        slot: "date",
        selected: true,
      },
      {
        text: "Montant",
        value: "ttc_dp",
        slot: "cur",
        align: "end",
        selected: true,
      },
      {
        text: "Montant Reglé",
        value: "montant",
        slot: "cur",
        align: "end",
        selected: true,
      },
    ],
    mvmheaders: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "Réference",
        value: "nobon",
        selected: true,
      },
      {
        text: "Date BL",
        value: "mvm_date",
        slot: "date",
        selected: true,
      },
      {
        text: "T.T.C",
        value: "ttc_bl",
        slot: "cur",
        align: "end",
        selected: true,
      },
      {
        text: "Montant Reglé",
        value: "montant",
        slot: "cur",
        align: "end",
        selected: true,
      },
    ],
    isPayeClosed: true,
    paiement: {},
    payedetail: {},
    changed: false,
    mvms_list: [],
    facts_list: [],
    tiers_agences: [],
    modes: [],
    banks: [],
    agences: [],
    comptes: [],
    document: {},
    isDocListClose: true,
  }),

  computed: {
    auth() {
      let a = this.paye_type == 1 ? "01016" : "01017";
      return a;
    },
    mybl() {
      return myBL;
    },

    Qdetaildelete() {
      return DELETE_PAYE_DETAIL;
    },
    Totals() {
      return TOTALS;
    },
    title() {
      return this.paye_type == 3 ? "Encaissements " : "Paiements";
    },
    Qselect() {
      return PAYE;
    },
    Qselectall() {
      return ALLPAYE;
    },
    Qdelete() {
      return DELETE_PAYE;
    },
    keydetail() {
      return this.aff + 1;
    },
    detail_list: function () {
      let list = [];
      if (this.paiement.id > 0) {
        list = this.paiement.payedetails;
      }
      return list;
    },

    detailtitle() {
      return this.paye_type == 3 ? "Lettrages " : "Demandes de Paiements";
    },

    headers_list() {
      let list = [];
      if (this.paiement.facture == "0") list = this.mvmheaders;
      else list = this.paye_type == 1 ? this.dpheaders : this.factheaders;

      return list;
    },
  },
  watch: {
    paye_type: {
      handler() {
        this.aff++;
        this.paiement = {};
        this.get_data();
      },
      deep: true,
    },
    "$store.state.filtre": {
      handler() {
        this.filtre = this.$store.state.filtre;
        this.klist++;
      },
    },
  },
  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.klist++;
    }
  },

  async mounted() {
    this.editer =
      (this.paye_type == 3 && this.$store.state.auth.includes("02017")) ||
      (this.paye_type == 1 && this.$store.state.auth.includes("02016")) ||
      this.$store.state.isadmin;

    this.valider =
      (this.paye_type == 3 && this.$store.state.auth.includes("03017")) ||
      (this.paye_type == 1 && this.$store.state.auth.includes("03016")) ||
      this.$store.state.isadmin;
    await this.get_data();
    if (this.$route.params.document) {
      this.document = this.$route.params.document;
      this.document.name = this.$route.params.name;
      this.paiement.id = -1;
      this.isPayeClosed = false;
      this.kf++;
    }
  },

  methods: {
    async requette(query, v, f) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: f,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async refresh() {
      if (this.paye_type == 1) {
        let r = await this.requette(ACTUAL_DPS, null, "no-cache");
        if (r) {
          this.facts_list = r.alldp;
        }
      }
      this.$store.dispatch("Changed", true);
    },
    show_attached(item) {
      this.paiement = item;
      this.isDocListClose = false;
      this.fdocl++;
    },
    async get_data() {
      this.$store.dispatch("Changed", true);
      this.headers[4].text = this.paye_type == 1 ? "Beneficiare" : "Tier";
      //this.headers[9].hiden = this.paye_type == 1;
      this.headers[10].hiden = this.paye_type == 1;
      this.headers[11].hiden = this.paye_type == 1;
      this.headers[12].hiden = this.paye_type == 1;
      this.headers[13].hiden = this.paye_type == 1;
      this.headers[14].hiden = this.paye_type == 1;
      let v, r;
      r = await this.requette(MODES);
      if (r) {
        this.modes = r.modes;
        this.headers[6].list = this.modes
          .filter((elm) => elm.type == 0)
          .map((elm) => elm.mode);
      }
      await this.refresh();

      r = await this.requette(AGENCES, v);
      if (r) {
        this.agences = r.agences.filter((elm) => elm.fk_pays == 13);
        this.tiers_agences = r.tiers_agences;
        this.headers[13].list = this.agences.map((elm) => elm.agence);
        this.banks = r.banks.filter((elm) => elm.fk_pays == 13);
        this.headers[7].list = this.banks.map((elm) => elm.bank);
        this.comptes = r.comptes.filter(
          (elm) => elm.struct_id == this.$store.state.me.struct_id
        );
      }

      //this.headers[0].slot = "href";
    },

    sumField(key) {
      // sum data in give key (property)
      return this.detail_list.reduce((a, b) => a + (b[key] || 0), 0);
    },
    OpenDetailForm(item) {
      this.payedetail = item;
      this.dkey++;
      this.isDetailClosed = false;
    },
    closeDetailForm() {
      this.isDetailClosed = true;
    },
    decimalDigits: function (value) {
      return value ? value.toFixed(2) : 0.0;
    },
    async PayeChange(item) {
      this.aff++;
      this.paiement = item;
      this.selitem = item;
    },
    OpenPayeForm(item) {
      this.paiement = item;
      if (item.col == "nopaye" || item.id < 0) {
        this.isPayeClosed = false;
        this.kf++;
      }
      if (item.col == "ttc_det") {
        this.showDetail = true;
      }
    },
    closePayeForm(item) {
      this.selitem = item;
      this.isPayeClosed = true;
    },
    PayeDelete() {
      this.aff++;
      this.paiement = {};
      this.get_data();
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
  },
};
</script>
